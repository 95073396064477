<template>
  <div class="orderTable">
    <div class="tableHeader">
      <div class="tableHeader__item" style="width: 220px">
        <el-checkbox style="margin-right: 10px;" v-model="chooseAll" @change="chooseHandle"></el-checkbox>
        <span>商品信息</span>
      </div>
      <div class="tableHeader__item" style="text-align: center;width: 220px">
        <span>买家信息</span>
      </div>
      <div class="tableHeader__item" style="text-align: center;width: 60px">
        <span>交易状态</span>
      </div>
      <div class="tableHeader__item" style="text-align: center;width: 60px">
        <span>操作</span>
      </div>
    </div>
    <div class="tableBody">
      <div class="orderItem" v-for="(item, index) in orderList">
        <div class="orderHeader">
          <div class="headerLeft">
            <el-checkbox style="margin-right: 10px;" v-model="item.choosed"></el-checkbox>
            <span>订单号：{{item.order_sn}}</span>
            <span>下单时间：{{item.create_at | formatDate}}</span>
            <span>支付方式：{{item.pay_channel}}</span>
          </div>
          <div class="headerRight">
          </div>
        </div>
        <div class="order">
          <div class="tableBody__item" style="width: 220px">
            <div class="goodsItem" v-for="goodsItem in item.goods_list">
              <el-image class="goodsItem__img" :src="goodsItem.pic" alt=""/>
              <div class="goodsItem__info">
                <div class="goodsItem__info__title">{{goodsItem.goods_name}}</div>
                <div class="goodsItem__info__spec">{{goodsItem.sku}}</div>
                <div class="goodsItem__info__kind">{{item.kind_text}}</div>
              </div>
            </div>
          </div>
          <div class="tableBody__item" style="width: 220px">
            <div class="customerInfo">
              <span class="customerInfoName">{{item.address_info.real_name}}</span>
              <span class="customerInfoAddress">{{item.address_info.country}}{{item.address_info.province}}{{item.address_info.city}}{{item.address_info.area}}{{item.address_info.street}}</span>
              <span class="customerInfoPhone">{{item.address_info.real_phone}}</span>
              <span class="customerInfoEmail">{{item.user_info.email}}</span>
            </div>
          </div>
          <div class="tableBody__item" style="width: 60px">
            <div class="orderState">
              <span class="orderState__state">{{item.order_state_text}}</span>
            </div>
          </div>
          <div class="tableBody__item" style="width: 60px">
            <div class="orderFuc">
              <el-button type="text" @click="receivedOrder(item.id)">确认收货</el-button>
              <el-button type="text" @click="toOrderDetail(item.id)">查看信息</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="emtpy" v-if="orderList.length == 0">暂无数据</div>
  </div>
</template>

<script>
import {apiReceiveOrder} from '@/request/api'
export default {
  inheritAttrs: false,
  name: "confirmOrderListTable",
  props:{
    orderList: {
      type: Array,
      default: []
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
  },
  watch: {
    orderList(val) {
      if(orderList.length == 0) {
        this.chooseAll = false
      }
    }
  },
  data(){
    return{
      chooseAll:false,
    }
  },
  created() {
  },
  methods:{
    chooseHandle(){
      // this.chooseAll = !this.chooseAll
      this.orderList.forEach(item => {
        item.choosed = this.chooseAll
      })
    },
    toOrderDetail(id){
      this.$router.push({
        path:'/order/orderDetail',
        query:{
          id:id
        }
      })
    },
    receivedOrder(id){
      this.$confirm('是否确认收货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        apiReceiveOrder({ids:[id]}).then(res => {
          if(res.code == 200){
            this.$message.success('已确认收货');
            this.$emit('refresh');
          }else{
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      })

    }
  }
}
</script>

<style lang="less" scoped>
.el-button {
  margin: 0;
}
.orderTable{
  width:100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .emtpy {
    width: 100%;
    height: 60px;
    border: 1px solid #ebeef5;
    border-top: 0;
    font-size: 14px;
    color: #909399;
    text-align: center;
    line-height: 60px;
  }
  .tableHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid #ebeef5;
    .tableHeader__item {
      padding: 12px 10px;
      box-sizing: border-box;
      font-size: 14px;
      color: #909399;
      font-weight: bold;
      flex-grow: 1;
    }
    .tableHeader__item~.tableHeader__item {
      border-left: 1px solid #ebeef5;
    }
  }
  .tableBody {
    width:100%;
    display: flex;
    flex-direction: column;
    .orderItem {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      border: 1px solid #ebeef5;
      .orderHeader,.orderFooter {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        height: 35px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: #909399;
        font-weight: bold;
        span ~ span {
          margin-left: 10px;
        }
      }
      .order {
        width: 100%;
        min-height: 70px;
        display: flex;
        flex-direction: row;
        border-top: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
        .tableBody__item {
          min-height: 70px;
          flex-grow: 1;
        }
        .tableBody__item~.tableBody__item {
          border-left: 1px solid #ebeef5;
        }
        .goodsItem {
          height: 80px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          box-sizing: border-box;
          .goodsItem__img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            margin-right: 10px;
          }
          .goodsItem__info {
            width: calc(100% - 85px);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            line-height: 18px;
            font-size: 14px;
            color: #909399;
            font-weight: bold;
            .goodsItem__info__title {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

            }
          }
        }
        .goodsItem~.goodsItem {
          border-top: 1px solid #ebeef5;
        }
        .goodsPriceItem {
          height: 80px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          padding: 10px;
          box-sizing: border-box;
          font-size: 14px;
          color: #909399;
          font-weight: bold;
          .goodsItem__info__count {

          }
          .goodsItem__info__unitPrice {
            margin-top: 10px;
          }
        }
        .goodsPriceItem~.goodsPriceItem {
          border-top: 1px solid #ebeef5;
        }
        .orderPriceInfo,.orderDefendRights,.customerInfo,.merchant,.orderState,.orderFuc {
          height:100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 18px;
          color: #909399;
          font-weight: bold;
          padding: 10px;
          box-sizing: border-box;
        }
        .customerInfo{
          align-items: flex-start;
          justify-content: flex-start;
          .customerInfoName {
            font-size: 14px;
            line-height: 24px;
          }
          .customerInfoAddress {
            width: 100%;
          }
          .customerInfoPhone {
            margin-top: 10px;
          }
          .customerInfoEmail {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>