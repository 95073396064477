<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <span>您确定自动收货吗?</span>
    <el-dialog
        width="30%"
        :visible.sync="innerVisible"
        append-to-body>
      <span>您已经对xxx个订单执行了自动收货动作</span>
      <div slot="footer">
        <el-button @click="closeInner">
          关闭窗口
        </el-button>
      </div>
    </el-dialog>
    <div slot="footer">

      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "confirmOrder",
  props:{
    id: {
      type: Number,
      default: []
    },
  },
  data(){
    return{
      innerVisible:false
    }
  },
  methods:{
    onClose() {
    },
    onOpen() {
    },
    closeInner(){
      this.innerVisible = false;
      this.close()
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      // this.close()
      this.innerVisible = true
    },
  }
}
</script>

<style scoped>

</style>