<template>
  <div class="page">
    <el-popover
        placement="bottom-end"
        width="600"
        :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
        v-model="visible">
      <el-form label-width="70px" label-position="left">
        <el-form-item label="搜索方式">
          <el-input size="small" v-model="searchObj[`${searchObj.searchWay}`]">
            <el-select style="width: 100px" v-model="searchObj.searchWay" slot="prepend" placeholder="请选择">
              <el-option v-for="(item,index) in searchParams['list-search-field']" :label="index" :value="item"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="发货时间">
          <el-date-picker
              size="small"
              v-model="searchObj.time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="display: flex;flex-direction: row;justify-content: center">
        <el-button size="mini" type="primary" @click="onSearch">搜索</el-button>
        <el-button size="mini" @click="initSearch">重置</el-button>
      </div>
      <el-button slot="reference">搜索</el-button>
    </el-popover>
    <div class="fucView">
      <span style="margin-right: 10px;">批量操作</span>
      <el-button type="text" @click="receivedOrder">自动收货</el-button>
    </div>
    <confirmOrderListTable :orderList="list" @refresh="getData" />
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="searchObj['per-page']" :current-page.sync="searchObj.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
  </div>
</template>

<script>
import confirmOrderListTable from "@/views/order/components/confirmOrderListTable";
import confirmOrder from "@/views/order/components/confirmOrder";
import {apiGetOrderList, apiGetOrderMix, apiReceiveOrder} from "@/request/api";
export default {
  name: "confirmOrderList",
  components: {
    confirmOrderListTable,
    confirmOrder
  },
  data(){
    return{
      showConfirmOrder: false,
      visible: false,
      pageCount: 0,
      searchObj: {},
      searchParams:{},
      pickerOptions:{
        shortcuts:[
          {
            text:'24小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'近3天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'近30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      list: [],
    }
  },
  created() {
    this.getSearchParams()
    this.initSearch()
    this.getData()
  },
  methods:{
    initSearch(){
      this.searchObj = {
        searchWay: '',
        time: [],

        create_at_start:'',
        create_at_end:'',

        page:1,
        'per-page':10,
      }
    },
    getSearchParams(){
      apiGetOrderMix({
        mix:'list-search-field'
      }).then(res => {
        if(res.code == 200){
          this.searchParams = res.data;
        }
      })
    },
    receivedOrder(){
      var ids = []
      this.list.forEach(item => {
        if(item.choosed){
          ids.push(item.id)
        }
      })
      this.$confirm('是否确认收货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        apiReceiveOrder({ids:ids}).then(res => {
          if(res.code == 200){
            this.$message.success('已确认收货');
            this.getData()
          }else{
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      })
    },
    pageChangeHandle(){
      this.getData()
    },
    onSearch(){
      this.searchObj.page = 1;
      this.getData()
    },
    getData(){
      let data = {
        page:this.searchObj.page,
        'per-page':this.searchObj['per-page'],
        order_state:'3',
        create_at_start:this.searchObj.time[0] || '',
        create_at_end:this.searchObj.time[1] || '',

      }
      if(this.searchObj.searchWay){
        data[`${this.searchObj.searchWay}`] = this.searchObj[`${this.searchObj.searchWay}`];
      }
      apiGetOrderList(data).then(res => {
        if(res.code == 200){
          this.pageCount = res.data.count;
          res.data.list.forEach(item => {
            item.choosed = false;
          })
          this.list = res.data.list;
        }
      })
    },
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.pageView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.el-form-item{
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>